<template>
  <div class="accountManage">
    <ds-header title="账户管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="账号：">
        <a-input v-model="searchForm.username" placeholder="请输入账号" style="width: 200px" />
      </a-form-item>
      <a-form-item label="角色：">
        <a-select
          v-model.trim="searchForm.roleId"
          style="width: 200px"
          placeholder="全部"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in roleOptions" :key="option.roleId" :value="option.roleId">
            {{ option.roleName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="所属租户：" v-if="isPlatformAccount">
        <a-select
          v-model="searchForm.tenantId"
          style="width: 200px"
          placeholder="全部"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in options" :key="option.tenantId" :value="option.tenantId">
            {{ option.tenantName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="姓名：">
        <a-input v-model="searchForm.nickname" placeholder="请输入姓名" style="width: 200px" />
      </a-form-item>
      <a-form-item>
        <SearchResetModule @search="search" @resetSearchForm="resetSearchForm"></SearchResetModule>
      </a-form-item>
    </a-form>
    <div style="padding-top: 15px" v-if="$checkButtonPermissions($buttonPermissionsConfig.authManage.accountManage.add)">
      <ds-button :type="$DSBT.DS_ADD" title="添加账号" @click="addAccount" />
    </div>
    <div style="padding-top: 15px">
      <ds-table
        :rowKey="(record, index) => record.userId"
        :scroll="{ x: true }"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        tableLayout="fixed"
        @change="pageChange"
        bordered
      >
        <span slot="nickname" slot-scope="text" class="action">
          <a-tooltip>
            <template slot="title">
              {{ text.nickname }}
            </template>
            <span class="field nick-name">{{ text.nickname }}</span>
          </a-tooltip>
        </span>
        <span slot="roleNameList" slot-scope="text" class="action">
          <a-tooltip>
            <template slot="title">
              {{ text.roleNameList }}
            </template>
            <span class="field role-name-list">{{ text.roleNameList }}</span>
          </a-tooltip>
        </span>
        <span slot="lessee" slot-scope="text" class="action">
          <a-tooltip>
            <template slot="title">
              {{ text.lessee }}
            </template>
            <span class="field width-200px">{{ text.lessee }}</span>
          </a-tooltip>
        </span>
        <span slot="action" slot-scope="text" class="action">
          <ActionModule
            :actions="actions"
            @action="action"
            :data="text"
            :status="text.status === 0 ? false : true"
            :isShow="text.userId !== currentUserId ? true : false"
          ></ActionModule>
        </span>
      </ds-table>
      <!-- <a-table
        :rowKey="(record, index) => record.userId"
        :scroll="{ x: 1500 }"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        tableLayout="fixed"
        @change="pageChange"
        bordered
      >
        <span slot="nickname" slot-scope="text" class="action">
          <a-tooltip>
            <template slot="title">
              {{ text }}
            </template>
            <span class="field nick-name">{{ text }}</span>
          </a-tooltip>
        </span>
        <span slot="roleNameList" slot-scope="text" class="action">
          <a-tooltip>
            <template slot="title">
              {{ text }}
            </template>
            <span class="field role-name-list">{{ text }}</span>
          </a-tooltip>
        </span>
        <span slot="lessee" slot-scope="text" class="action">
          <a-tooltip>
            <template slot="title">
              {{ text }}
            </template>
            <span class="field role-name-list">{{ text }}</span>
          </a-tooltip>
        </span>
        <span slot="action" slot-scope="text" class="action">
          <ActionModule
            :actions="actions"
            @action="action"
            :data="text"
            :status="text.status === 0 ? false : true"
            :isShow="text.userId !== currentUserId ? true : false"
          ></ActionModule>
        </span>
      </a-table> -->
    </div>
    <AddAccount
      :isShow.sync="isShow"
      :handleStatus="handleStatus"
      :accountDetail="accountDetail"
      @refresh="refresh"
    ></AddAccount>
    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
  </div>
</template>

<script>
import * as api from "@/api/authManage/accountManage";
import AddAccount from "./components/addAccount";
import { REQUEST_CODE } from "@/constant/status";
import moment from "moment";
import { mapState } from "vuex";
import { getLesseeList } from "@/api/authManage/lesseeManage";
import { getRoleList } from "@/api/authManage/roleManage";
import { isPlatformAccount } from "@/config";
import { getLocalStorage } from "@/utils/storage";
import PagingConfig from "@/utils/pagingConfig";
import ActionConfig from "@/utils/actionConfig";
import ActionModule from "@/components/actionModule";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import ConfirmConfig from "@/utils/confirmConfig";
import SearchResetModule from "@/components/searchResetModule";

export default {
  name: "AccountManage",
  components: {
    AddAccount,
    ActionModule,
    ConfirmBoxModule,
    SearchResetModule,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  data() {
    return {
      //判断是否是平台账号
      isPlatformAccount: isPlatformAccount,
      //当前用户id
      currentUserId: "",
      //是否显示
      isShow: false,
      //操作账户页面状态 1.添加, 2.查看, 3.编辑
      handleStatus: undefined,
      //账户详情
      accountDetail: undefined,
      searchForm: {
        //账号
        username: "",
        //角色
        roleName: undefined,
        //所属租户
        tenantId: undefined,
        //姓名
        nickname: "",
        //租户名称
        lessee: undefined,
        roleId:undefined
      },
      //角色选项
      roleOptions: [],
      //租户选项
      options: [],
      //展示数据
      table: {
        //数据
        dataInfo: [],
        //title数据
        columns: [
          {
            title: "账号",
            dataIndex: "username",
            key: "username",
            width: 150,
          },
          {
            title: "姓名",
            dataIndex: "nickname",
            key: "nickname",
            width: 150,
            scopedSlots: { customRender: "nickname" },
          },
          {
            title: "电话",
            dataIndex: "mobile",
            key: "mobile",
            width: 150,
          },
          {
            title: "角色",
            dataIndex: "roleNameList",
            key: "roleNameList",
            scopedSlots: { customRender: "roleNameList" },
            width: 300,
          },
          {
            title: "状态",
            dataIndex: "status",
            key: "status",
            width: 150,
            customRender: (text) => {
              return text === 1 ? "禁用" : "启用";
            },
          },
          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            customRender: (text) => {
              return moment(text).format("YYYY-MM-DD HH:mm:ss");
            },
            // width: 200,
          }
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: PagingConfig.defaultPage,
          pageSize: PagingConfig.defaultPageSize,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      //操作集合
      actions: [
        {
          type: ActionConfig.look.ACTION_TYPE,
          title: "详情",
          permissions: this.$buttonPermissionsConfig.authManage.accountManage.detail
        },
        {
          type: ActionConfig.edit.ACTION_TYPE,
          title: ActionConfig.edit.ACTION_TITLE,
          permissions: this.$buttonPermissionsConfig.authManage.accountManage.edit
        },
        {
          type: ActionConfig.delete.ACTION_TYPE,
          title: ActionConfig.delete.ACTION_TITLE,
          permissions: this.$buttonPermissionsConfig.authManage.accountManage.delete
        },
        {
          type: ActionConfig.disabled.ACTION_TYPE,
          permissions: this.$buttonPermissionsConfig.authManage.accountManage.enable
        },
      ],
    };
  },
  methods: {
    //选择租户
    selectedTenant(tenantId) {
      this.options.forEach((item) => {
        if (item.tenantId === tenantId) {
          this.searchForm.lessee = item.tenantName;
        }
      });
    },
    //选择角色
    selectedRole(roleId) {
      this.roleOptions.forEach((item) => {
        if (item.roleId === roleId) {
          this.searchForm.roleName = item.roleName;
        }
      });
    },
    //查询
    search() {
      this.refreshData();
    },
    //重置
    resetSearchForm() {
      this.searchForm = {
        username: "",
        roleName: undefined,
        tenantId: undefined,
        nickname: "",
      };
      this.refreshData();
    },
    //添加账号
    addAccount() {
      this.handleStatus = 1;
      this.isShow = true;
    },
    //详情
    detail(text) {
      this.handleStatus = 2;
      this.isShow = true;
      this.getAccountDetail(text.userId);
    },
    //编辑
    edit(text) {
      this.handleStatus = 3;
      this.isShow = true;
      this.getAccountDetail(text.userId);
    },
    //删除
    async remove(text) {
      const confirmData = {
        title: "是否确认删除该账户?",
        content: "删除后该账号将不可使用",
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.deleteAccount(text.userId);
    },
    //禁用
    async forbidden(text) {
      const title = text.status === 0 ? "禁用" : "启用";
      const confirmData = {
        title: `${title}后该账户将被${title}`,
        content: `${title}后该账户将被${title}`,
        type: text.status === 0 ? ConfirmConfig.CONFIRM_TYPE_WARNING : ConfirmConfig.CONFIRM_TYPE_SUCCESS,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.accountDisable(text.userId, text.status === 0 ? 1 : 0);
    },
    //列表操作
    action(actionType, data) {
      if (actionType === ActionConfig.look.ACTION_TYPE) {
        this.detail(data);
      } else if (actionType === ActionConfig.edit.ACTION_TYPE) {
        this.edit(data);
      } else if (actionType === ActionConfig.delete.ACTION_TYPE) {
        this.remove(data);
      } else if (actionType === ActionConfig.disabled.ACTION_TYPE) {
        this.forbidden(data);
      }
    },
    //分页操作
    pageChange(option) {
      this.refreshData(option.current, option.pageSize);
    },
    //刷新界面
    refresh() {
      this.getAccountList();
    },

    /**网络请求 */
    //获取账户列表
    async getAccountList() {
      try {
        const result = await api.getAccountList(
          this.getListDataTidy(),
          this.table.pagination.current,
          this.table.pagination.pageSize
        );

        if (result.code === REQUEST_CODE) {
          this.table.dataInfo = result.data.menuList;
          if (this.table.dataInfo) {
            this.table.dataInfo.forEach((item) => {
              let roleNameList = [];
              if (item.roleNameAndIdDTOSet) {
                item.roleNameAndIdDTOSet.forEach((role) => {
                  roleNameList.push(role.roleName);
                });
              }
              item.roleNameList = roleNameList.join(",");
            });
          }
          this.table.pagination.total = result.data.count;
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        this.$message.error("获取账户列表失败");
      }
    },
    //获取账户详情
    async getAccountDetail(userId) {
      try {
        const result = await api.getAccountDetail(userId);
        if (result.code === REQUEST_CODE) {
          this.accountDetail = result.data;
        }
      } catch (error) {}
    },
    //账户启禁用
    async accountDisable(userId, disableStatus) {
      try {
        const result = await api.accountDisable(userId, disableStatus);
        if (result.code === REQUEST_CODE) {
          this.$message.success(result.msg);
          this.refreshData();
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        this.$message.error("操作失败");
      }
    },
    //删除账户
    async deleteAccount(userId) {
      try {
        const result = await api.deleteAccount(userId);
        if (result.code === REQUEST_CODE) {
          this.$message.success(result.msg);
          this.refreshData();
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        this.$message.error("操作失败");
      }
    },
    //获取租户列表
    async getLessee() {
      try {
        const searchForm = {
          userName: "",
          tenantName: "",
        };
        const result = await getLesseeList(searchForm, 1, 9999);
        this.options = result.data.list;
      } catch (error) {}
    },
    //获取角色列表
    async getRole() {
      const searchForm = {
        roleName: "",
        status: "",
        tenantId: "",
      };
      try {
        const result = await getRoleList(searchForm, 1, 9999);
        if (result.code === REQUEST_CODE) {
          this.roleOptions = result.data.roleList;
        }
      } catch (error) {
        this.$message.error("获取角色列表失败");
      }
    },

    //刷新数据
    refreshData(current = PagingConfig.defaultPage, pageSize = PagingConfig.defaultPageSize) {
      if (pageSize !== this.table.pagination.pageSize) {
        this.table.pagination.current = PagingConfig.defaultPage;
      } else {
        this.table.pagination.current = current;
      }
      this.table.pagination.pageSize = pageSize;
      this.getAccountList();
    },

    /**数据处理 */
    //获取用户列表数据处理
    getListDataTidy() {
      if (this.isPlatformAccount) {
        return { ...this.searchForm };
      } else {
        return { ...this.searchForm, tenantId: getLocalStorage("user").tenantId };
      }
    },
  },
  created() {
    this.$checkTableActionIsShow(this.actions) && this.table.columns.push(this.$backTableAction({width: "200px", fixed: 'right',}));
    this.refreshData();
    this.getRole();
    if (this.isPlatformAccount) {
      this.getLessee();
      this.table.columns.splice(5, 0, {
        title: "所属租户",
        dataIndex: "lessee",
        key: "lessee",
        scopedSlots: { customRender: "lessee" },
        width: "200px",
      });
    }
    this.currentUserId = getLocalStorage("user").userId;
  },
};
</script>

<style lang="scss" scoped>
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.nick-name {
  width: 80px;
}
.role-name-list {
  width: 300px;
}
.width-200px {
  width: 200px;
}

</style>

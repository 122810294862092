import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"
import {getLocalStorage} from "@/utils/storage"

//获取项目列表
export const getAccountList = (data, pageNum, pageSize) => {
  const submitData = {
    ...data,
    pageNum,
    pageSize
  }
  return fetchApi(URL.GET_ACCOUNT_LIST, submitData, "post", "data");
};

//添加账户
export const addAccount = data => {
  const submitData = {
    ...data,
    tenantId: getLocalStorage("user").tenantId
  }
  return fetchApi(URL.ADD_ACCOUNT, submitData, "post", "data");
}

//获取账户详情
export const getAccountDetail = userId => {
  const submitData = {
    userId
  }
  return fetchApi(URL.GET_ACCOUNT_DETAIL, submitData);
}

//编辑账户
export const editAccount = data => {
  const submitData = {
    ...data,
  }
  return fetchApi(URL.EDIT_ACCOUNT, submitData, "post", "data");
}

//账户启禁用
export const accountDisable = (userId, disableStatus) => {
  const submitData = {
    userId,
    disableStatus
  }
  return fetchApi(URL.ACCOUNT_DISABLE, submitData);
}

//删除账户
export const deleteAccount = (userId) => {
  const submitData = {
    userId,
  }
  return fetchApi(URL.DELETE_ACCOUNT, submitData, "post");
}
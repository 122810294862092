<template>
  <div class="addAccount" v-if="isShow">
    <a-modal v-model="visible" :title="showTitle" :footer="null" :maskClosable="false" >
      <div>
        <a-form-model
          layout="horizontal"
          ref="ruleForm"
          :model="form"
          :rules="isDisable ? null : rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="账号：" prop="username">
            <a-input
              placeholder="请输入账号"
              v-model.trim="form.username"
              @input="(e) => (form.username = validAllowCharNum(e))"
              :disabled="isDisable ? isDisable : handleStatus === 3 ? true : false"
              autocomplete="new-password"
            />
          </a-form-model-item>
          <a-form-model-item
            label="密码："
            :prop="handleStatus !== 3 ? 'password' : editStatusModifyPasswordStatus ? 'password' : ''"
          >
            <a-input-password
              placeholder="请输入密码"
              v-model.trim="form.password"
              :disabled="isDisable"
              :visibilityToggle="false"
              @change="modifyPassword"
              autocomplete="new-password"
            />
            <div style="font-size: 10px;line-height: normal;">8-16位字符（需包含字母、数字、特殊字符，其中任意两种），区分大小写</div>
          </a-form-model-item>
          <a-form-model-item label="姓名：" prop="nickname">
            <a-input
              placeholder="请输入姓名"
              :value="form.nickname"
              @input="(e) => (form.nickname = nameVerify(e))"
              :disabled="isDisable"
            />
          </a-form-model-item>
          <a-form-model-item label="电话：" prop="mobile">
            <a-input placeholder="请输入电话" v-model.trim="form.mobile" :disabled="isDisable" />
          </a-form-model-item>
          <a-form-model-item label="角色：" prop="roleIdList">
            <a-select
              mode="multiple"
              v-model="form.roleIdList"
              placeholder="请选择角色(可多选)"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              @change="selectedRole"
              :disabled="isDisable"
              :filter-option="filterOption"
            >
              <a-select-option v-for="option in roleOptions" :key="option.roleId" :value="option.roleId">
                {{ option.roleName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="merchantVisible" label="商户：" prop="merchantInfoList">
            <a-select
              mode="multiple"
              v-model="merchantId"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              placeholder="请选择商户"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              @change="selectedMerchant"
              :disabled="isDisable"
            >
              <a-select-option v-for="option in merchantList" :key="option.merchantId" :value="option.merchantId">
                {{ option.merchantName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="组织架构">
            <a-tree-select
              mode="multiple"
              multiple
              :value="selectedValue"
              :disabled="isDisable"
              v-model="form.organizationIdList"
              placeholder="请选择组织架构(可多选)"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              @change="selectTree"
              show-search
              tree-node-filter-prop="title"
              :replaceFields="replaceTypeTreeFields"
              :tree-data="orArr"
            >
            </a-tree-select>
          </a-form-model-item>
          <a-form-model-item label="双因子认证" required>
            <a-radio-group name="radioGroup" :disabled="isDisable" @change="changeRadio" v-model="form.factorStatus">
              <a-radio :value="true">
                开启
              </a-radio>
              <a-radio :value="false">
                关闭
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="状态：" prop="status">
            <a-select
              v-model="form.status"
              placeholder="请选择"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              :disabled="isDisable"
            >
              <a-select-option v-for="option in options" :key="option.id" :value="option.id">
                {{ option.value }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div style="text-align: center" v-show="!isDisable">
        <ds-button :type="$DSBT.DS_CANCEL" @click="cancel" />
        <ds-button :type="$DSBT.DS_SAVE" @click="save" style="margin-left: 20px" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getMerchantList, getRoleList,getOrganizationTree } from '@/api/authManage/roleManage'
import { REQUEST_CODE } from "@/constant/status";
import * as api from "@/api/authManage/accountManage";
import { isValidAccountNoAllNumber, isvalidPhone,isValidPassword } from "@/utils/validate";
import debounce from "lodash/debounce";
export default {
  name: "AddAccount",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    handleStatus: {
      type: Number,
      default: undefined,
    },
    accountDetail: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    merchantVisible () {
      return this.form.roleIdList.some(item => {
        if (item === '04f841ce5e400000' || item?.roleId === '04f841ce5e400000') {
          return true
        }
      })
    },
    visible: {
      set() {
        this.resetData();
        this.$emit("update:isShow", false);
      },
      get() {
        return this.isShow;
      },
    },
    isDisable() {
      return this.handleStatus === 2;
    },
    showTitle() {
      if (this.handleStatus === 1) {
        return "添加账户";
      } else if (this.handleStatus === 2) {
        return "账号详情";
      } else {
        return "编辑账户";
      }
    },
  },
  watch: {
    accountDetail(newVal) {
      let roleIdList = [];
      if (newVal.roleNameAndIdDTOSet && newVal.roleNameAndIdDTOSet.length) {
        newVal.roleNameAndIdDTOSet.forEach((item) => {
          roleIdList.push(item.roleId);
        });
      }
      this.merchantId = newVal.merchantInfoList?.map(item => item.merchantId)
      this.form = {
        username: newVal.username,
        password: newVal.password,
        nickname: newVal.nickname,
        mobile: newVal.mobile,
        merchantInfoList: newVal.merchantInfoList || [],
        // Todo
        roleIdList,
        organizationIdList:newVal.organizationIdList,
        factorStatus: newVal.factorStatus,
        status: newVal.status !== null ? `${newVal.status}` : undefined,
        userId: newVal.userId,
      };
      this.getRole();
      this.getOrganizationTree()
    },
  },
  data() {
    return {
      replaceTypeTreeFields:{
        children: 'children',
        title: 'organizationName',
        value: 'organizationId',
        key: 'organizationId',
      },
      selectedValue:[],
      merchantList: [],
      merchantId: [],
      show: true,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      //编辑状态下修改密码时 是否校验逻辑
      editStatusModifyPasswordStatus: false,
      form: {
        //账号
        username: "",
        //密码
        password: "",
        //姓名
        nickname: "",
        //电话
        mobile: "",
        //角色
        roleIdList: [],
        //组织架构集合
        organizationIdList:[],
        // 是否双因子认证
        factorStatus: false,
        //状态
        status: undefined,
        merchantInfoList: []
      },
      orArr:[],
      merchant: {},
      rules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          { max: 50, message: "账号最长50位", trigger: "blur" },
          { validator: isValidAccountNoAllNumber, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 8, message: "请根据规则输入密码", trigger: "blur" },
          { max: 16, message: "请根据规则输入密码", trigger: "blur" },
          { validator: isValidPassword, trigger: "blur" },
        ],
        nickname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { validator: isvalidPhone, trigger: "blur" },
        ],
        roleIdList: [{ required: true, message: "请选择角色", trigger: "change" }],
        merchantInfoList: [{ required: true, message: "请选择商户", trigger: "change" }],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
      //角色选项
      roleOptions: [
        {
          id: "1",
          value: "角色1",
        },
        {
          id: "2",
          value: "角色2",
        },
        {
          id: "3",
          value: "角色3",
        },
      ],
      //租户选项
      options: [
        {
          id: "0",
          value: "启用",
        },
        {
          id: "1",
          value: "禁用",
        },
      ],
    };
  },
  methods: {
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    //保存
    save: debounce(function () {
      if (this.form.factorStatus) {
        if (!this.checkPhone(this.form.mobile)) {
          this.$message.warning('请填写正确的电话号码，再进行双因子认证开启')
          return;
        }
      }
      this.$refs.ruleForm.validate((valid) => {
        try {
          this.merchant = []
          this.merchantList.forEach(item => {
            if (this.merchantId && this.merchantId.includes(item.merchantId)) {
              this.merchant.push(item)
            }
          })

          this.form.merchantInfoList.splice(0)
          this.form.merchantInfoList.push(...this.merchant)
        } catch (e) {
          console.log(e)
        }
        if (valid) {
          if (this.editStatusModifyPasswordStatus && (this.form.password.length < 8 || this.form.password.length > 16)) {
            return false;
          }
          if (this.handleStatus === 1) {
            this.addAccount();
          } else {
            this.editAccount();
          }
        } else {
          return false;
        }
      });
    }, 500),
    selectTree(e) {//选择组织架构回调
      this.form.organizationIdList = e
    },
    //取消
    cancel() {
      this.resetData();
      this.$emit("update:isShow", false);
    },
    //选中角色
    selectedRole() {},
    selectedMerchant () {
      try {
        this.merchant = []
        this.merchantList.forEach(item => {
          if (this.merchantId && this.merchantId.includes(item.merchantId)) {
            this.merchant.push(item)
          }
        })

        this.form.merchantInfoList.splice(0)
        this.form.merchantInfoList.push(...this.merchant)
      } catch (e) {
        console.log(e)
      }
    },
    //重置数据
    resetData() {
      this.merchantId = []
      this.form = {
        username: "",
        password: "",
        nickname: "",
        mobile: "",
        roleIdList: [],
        organizationIdList:[],
        merchantInfoList: [],
        factorStatus: false,
        status: undefined,
      };
      this.editStatusModifyPasswordStatus = false;
      this.$refs[`ruleForm`].resetFields();
    },
    //修改密码
    modifyPassword() {
      if (!this.editStatusModifyPasswordStatus) {
        this.editStatusModifyPasswordStatus = true;
      }
    },
    /**网络请求 */
    //获取角色列表
    async getRole() {
      const searchForm = {
        roleName: "",
        status: "1",
        tenantId: "",
      };
      try {
        const result = await getRoleList({...searchForm, roleIdList: this.form.roleIdList}, 1, 9999);
        if (result.code === REQUEST_CODE) {
          this.roleOptions = result.data.roleList;
        }
      } catch (error) {
        this.$message.error("获取角色列表失败");
      }
    },
    async getOrganizationTree() {//获取组织架构树形图
      // let tenantId = JSON.parse(localStorage.getItem('user')).userId
      let res = await getOrganizationTree()
      if(res.code == '200'){
        this.orArr = res.data
      }else{
        this.$message.error(res.msg);
      }
    },
    // 获取商户
    async loadMerchantList () {
      try {
        const { data } = await getMerchantList()
        this.merchantList = data
      } catch (e) {
        this.$message.error("获取商户列表失败");
      }
    },
    //添加账号
    async addAccount() {
      try {
        const result = await api.addAccount(this.form);
        if (result.code === REQUEST_CODE) {
          this.$message.success(result.msg);


          this.$emit("update:isShow", false);
          this.$emit("refresh");
          this.resetData();
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        this.$message.error("添加账户失败");
      }
    },
    //编辑账户
    async editAccount() {
      if (this.editStatusModifyPasswordStatus && (this.form.password.length < 8 || this.form.password.length > 20)) {
        return;
      }
      try {
        const result = await api.editAccount(this.form);
        if (result.code === REQUEST_CODE) {
          this.$message.success(result.msg);
          this.$emit("update:isShow", false);
          this.$emit("refresh");
          this.resetData();
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        this.$message.error("编辑账户失败");
      }
    },
    changeRadio(evn) {
      // 如果开启双因子认证就要验证mobile是否是手机号
      if (evn.target.value) {
        if ( !this.checkPhone(this.form.mobile) ) {
          this.$message.warning('请填写正确的电话号码，再进行双因子认证开启')
          this.form.factorStatus = false
        }
      }
    },
    // 校验手机号
    checkPhone (val) {
      const reg = /^(1[3465789]\d{9})$/;
      if (reg.test(val)) {
        return true;
      }
      return false;
    }
  },
  created() {
    if (this.handleStatus !== 1) {
      this.getRole();
      this.getOrganizationTree()
      this.loadMerchantList()
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
